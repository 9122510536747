import Enum from "app/core/utilites/enum/Enum";

let sourceEnum = new Enum({
    fields: [
        {key: "mobile", value: "mobile"}
    ]
});

export default {
    getInstance() {
        return sourceEnum;
    }
};
